import { Fragment, useCallback } from 'react';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import { BUILD_FLOW_STEPS } from '@constants';
import { useBFContext } from '@context';
import { Checkmark, Flex } from '@components';
import { useTranslation } from '@utils/index';
import styles from './StepTracker.module.scss';

type Tracker = {
	isComplete: boolean;
	isCurrent: boolean;
	copy: string;
	step?: BUILD_FLOW_STEPS;
	isSkip?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		gotToStep: (stepName: string) => translator('got-to-step', { stepName }),
		frameColor: translator('frame-color'),
		lensType: translator('lens-type'),
		subscription: translator('subscription'),
		baseFrame: translator('base-frame'),
		topFrames: translator('top-frames'),
	};
};

const generateSteps = (currentStep, isQuickAdd = false, translations): Tracker[] => {
	return [
		!isQuickAdd && {
			copy: translations.frameColor,
			isComplete: currentStep > BUILD_FLOW_STEPS.COLOR,
			isCurrent: currentStep === BUILD_FLOW_STEPS.COLOR,
		},
		!isQuickAdd && {
			copy: translations.lensType,
			isComplete: currentStep > BUILD_FLOW_STEPS.CUSTOMIZE,
			isCurrent:
				currentStep === BUILD_FLOW_STEPS.PROGRESSIVE ||
				currentStep === BUILD_FLOW_STEPS.LENS ||
				currentStep === BUILD_FLOW_STEPS.CUSTOMIZE,
		},
		isQuickAdd && {
			copy: translations.baseFrame,
			isComplete: currentStep === BUILD_FLOW_STEPS.TOP_FRAMES,
			isCurrent: currentStep < BUILD_FLOW_STEPS.TOP_FRAMES,
		},
		{
			copy: translations.topFrames,
			isComplete: currentStep > BUILD_FLOW_STEPS.TOP_FRAMES,
			isCurrent: currentStep === BUILD_FLOW_STEPS.TOP_FRAMES,
		},
	].filter(Boolean);
};

const StepTracker = () => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const { setStep, step, isQuickAdd } = useBFContext();
	const steps: Tracker[] = generateSteps(step, isQuickAdd, translations);

	function handleGoTo(goToStep) {
		switch (goToStep) {
			case 'GO_TO_COLORS':
				setStep(BUILD_FLOW_STEPS.COLOR);
				break;
			case 'GO_TO_PRESCRIPTIONS':
				setStep(BUILD_FLOW_STEPS.LENS);
				break;
			case 'GO_TO_COLLECTIONS':
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				break;
		}
	}

	const renderTrackerItem = useCallback(
		({ isComplete, isCurrent, copy, isSkip }: Tracker, i: number) => {
			const styleState = { [styles['complete']]: isComplete, [styles['current']]: isCurrent, [styles['skip']]: isSkip };
			const stroke = isComplete ? variables.blue1 : variables.gray3;
			const hasDash = i < steps.length - 1;

			let goToStep = 'GO_TO_COLORS';
			if (copy === 'Lens Type') goToStep = 'GO_TO_PRESCRIPTIONS';
			if (copy === 'Top Frames') goToStep = 'GO_TO_COLLECTIONS';

			return (
				<li
					data-test-step={isCurrent ? copy : ''}
					className={styles.step}
					title={isComplete ? translations.gotToStep(copy) : undefined}
				>
					<Flex column align='center'>
						<div className={cn(styles.circle, styleState)}>
							{isComplete && <Checkmark thick />}
							{!isComplete && <span className={isCurrent || isComplete ? styles.complete : ''}>{i + 1}</span>}
						</div>
						<div className={cn(styles.copy, styleState)} onClick={() => isComplete && handleGoTo(goToStep)}>
							{copy}
						</div>
					</Flex>
					{hasDash && (
						<div
							className={styles.line}
							style={{
								backgroundColor: stroke,
							}}
						/>
					)}
				</li>
			);
		},
		[steps.length]
	);

	return (
		<ul className={styles.container} data-step-tracker>
			{steps.map((mark, index) => (
				<Fragment key={mark.copy}>{renderTrackerItem(mark, index)}</Fragment>
			))}
		</ul>
	);
};

export default StepTracker;
